import React from 'react'
import { graphql } from 'gatsby'

import 'twin.macro'

import SEO from '../../components/seo'
import SiteHead from '../../components/site-head'
import PageContent from '../../components/page-content'
import ArticleContainer from '../../components/article-container'
import ArticleHeading from '../../components/article-heading'
import Text from '../../components/text'
import SiteFoot from '../../components/site-foot'

const ProjectEntry = ({ data }) => {
  const { entry } = data

  return (
    <>
      <SEO data={entry.seomatic} />
      <SiteHead />
      <PageContent>
        <ArticleContainer>
          <ArticleHeading heading={entry.title} />
          <div tw="my-12">
            <Text body={entry.body} />
          </div>
        </ArticleContainer>
        <SiteFoot />
      </PageContent>
    </>
  )
}

export default ProjectEntry

export const query = graphql`
  query($id: ID) {
    entry: craftTextArticleTextArticleEntry(remoteId: { eq: $id }) {
      seomatic {
        ...SEO
      }
      title
      body
    }
  }
`
